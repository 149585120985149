<template>
  <div>
    <b-overlay
      fixed
      :show="showen"
      z-index="300"
      no-wrap
      class="main-overlay"
      opacity="0.7"
      :no-center="true"
    >
      <template #overlay>
        <div class="show-post">
          <div class="side-post">
            <div class="top">
              <div class="logo">
                <img
                  :src="
                    require('@/assets/images/pages/classRoom/boards/posts/showPost/logoFS.png')
                  "
                  alt="logo-img"
                />
                <small v-if="data.title" class="text">
                  {{
                    data.title.split("").length >= 19
                      ? data.title.substring(0, 19)
                      : data.title
                  }}
                </small>
              </div>

              <div class="arrows">
                <div class="arrow" @click="prev" v-if="currentIndex != 0">
                  <img
                    :src="
                      require('@/assets/images/pages/classRoom/boards/posts/showPost/arrow.png')
                    "
                    alt=""
                  />
                </div>
                <span class="ml-1 mr-1"
                  >{{ currentIndex + 1 }}:{{ listPosts.length }}</span
                >
                <div
                  class="arrow"
                  @click="next"
                  v-if="currentIndex != listPosts.length - 1"
                >
                  <img
                    :src="
                      require('@/assets/images/pages/classRoom/boards/posts/showPost/arrow.png')
                    "
                    alt=""
                  />
                </div>
              </div>
              <div class="desc">
                <div class="teacher-info">
                  <img
                    :src="
                      userData.media.length != 0
                        ? userData.media[0].path
                        : require('@/assets/images/pages/classRoom/boards/posts/showPost/dummyPerson.png')
                    "
                    alt=""
                  />
                  <div class="text">
                    <span>
                      {{ userData.first_name }} {{ userData.middle_name }}
                      {{ userData.last_name }}
                    </span>
                    <span> {{ timeConvertFunction(data.created_at) }} </span>
                  </div>
                </div>
                <div
                  class="course-info"
                  v-if="Array.isArray(data.media) && data.media.length != 0"
                >
                  <div>{{ data.title }}</div>
                  <div>
                    {{ data.text }}
                  </div>
                </div>
                <div class="comments-section">
                  <div class="dash"></div>
                  <div
                    class="comment"
                    v-for="comment of comments"
                    :key="comment.id"
                  >
                    <div class="info">
                      <img
                        :src="
                          [...comment.user.media].length != 0
                            ? comment.user.media[0].path
                            : require('@/assets/images/pages/classRoom/boards/posts/showPost/dummyPerson.png')
                        "
                        alt=""
                      />

                      <div class="text">
                        <span
                          >{{ comment.user.first_name }}
                          {{ comment.user.last_name }}
                          <span
                            class="user-type"
                            v-if="comment.user.id === data.user.id"
                            >Creator</span
                          ></span
                        >
                        <span>{{
                          timeConvertFunction(comment.created_at)
                        }}</span>
                      </div>
                      <b-dropdown
                        class="dropdwon"
                        variant="link"
                        toggle-class="text-decoration -none"
                        no-caret
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>

                        <b-dropdown-item
                          tag="li"
                          class="p-1"
                          @click="addReport(comment)"
                        >
                          <span>{{ $t("g.addReport") }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                      <div class="content">
                        {{ comment.comment }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="preview-section">
            <b-row class="col-1 x-row">
              <img
                :src="
                  require('@/assets/images/pages/classRoom/boards/posts/showPost/x.png')
                "
                alt=""
                class="cursor-pointer"
                @click="close()"
              />
            </b-row>
            <b-row
              class="main-img-preview"
              v-if="Array.isArray(data.media) && data.media.length != 0"
            >
              <img class="main-img" :src="data.media[0].path" alt="" />
            </b-row>
            <b-row v-else>
              <div class="description-div">
                <div class="title">
                  {{ data.title }}
                </div>
                <div class="description">
                  {{ data.text }}
                </div>
              </div>
            </b-row>
          </div>
        </div>
      </template>
    </b-overlay>
    <report-component
      :reportable_id="report.reportable_id"
      :reportable_type="report.reportable_type"
    />
  </div>
</template>
<script>
import ReportComponent from "@/components/shared/ReportComponent/index.vue";
import {
  BCol,
  BRow,
  BOverlay,
  BFormGroup,
  BFormInput,
  BForm,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";

export default {
  name: "ShowPost",
  components: {
    BCol,
    BOverlay,
    BRow,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,

    BButton,
    ReportComponent,
  },
  data() {
    return {
      currentPostId: null,
      currentlistId: null,
      boardId: null,
      showen: true,
      currentIndex: null,
      listPosts: [],
      userData: {},
      data: {},
      comments: [],
      commentToSent: null,
      page_data: {
        post_id: this.$route.params.postId,
        board_id: this.$route.params.boardId,
        list_id: this.$route.params.listId,
      },
      report: {
        show: false,
        reportable_id: null,
        reportable_type: null,
      },
    };
  },
  watch: {
    currentPostId(newval, oldVal) {
      this.getAllData();
    },
  },

  beforeMount() {
    this.currentPostId = this.page_data.post_id;
    this.boardId = this.page_data.board_id;
    this.currentlistId = this.page_data.list_id;
    this.getAllData();
    this.getMyData();
    this.getlistdata();
  },
  methods: {
    next() {
      if (this.currentIndex < this.listPosts.length - 1) {
        this.currentPostId = this.listPosts[this.currentIndex + 1].id;
      }
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentPostId = this.listPosts[this.currentIndex - 1].id;
      }
    },
    close() {
      this.$router.push({
        name: "Boards",
        params: { id: this.boardId },
      });
    },

    getlistdata() {
      this.$http
        .get(
          `guardian/boards/${this.boardId}/lists/${this.currentlistId}/posts`
        )
        .then((response) => {
          this.listPosts = response.data.data;
          this.getCurrentPostIndex();
        })
        .catch((error) => console.log(error));
    },
    getMyData() {
      let newdata = JSON.parse(localStorage.getItem("userData"));
      this.userData = newdata;
    },
    getAllData() {
      this.$http
        .get(
          `guardian/boards/${this.boardId}/lists/${this.currentlistId}/posts/${this.currentPostId}`
        )
        .then((response) => {
          this.data = response?.data.data;
          this.getCurrentPostIndex();
          this.comments = response?.data.data.comments;
        });
    },
    getCurrentPostIndex() {
      // get current post index in list of post array
      const currentPostId = this.currentPostId;
      this.currentIndex = this.listPosts.findIndex(
        (e) => e["id"] == currentPostId
      );
    },
    Toast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: true,
      });
    },
    timeConvertFunction(time) {
      const timestampDate = new Date(time);
      const currentDate = new Date();
      const timeDifference = currentDate - timestampDate;
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      } else {
        return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
      }
    },
    addReport(userData) {
      console.log(userData);
      this.report.reportable_id = userData.id;
      this.report.reportable_type = "comment";
      // console.log(this.$bvModal);
      this.$bvModal.show("bv-modal-report");
    },
  },
};
</script>

<style lang="scss" src="./_showPost.scss"></style>
